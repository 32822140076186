import "core-js/stable"
import "regenerator-runtime/runtime"
import Automount from "utils/automount-next"
import "next/style.scss"
import 'utils/rails'
import 'controllers'

document.addEventListener("DOMContentLoaded", () => {
  Automount("hrfilter-news-block", () => import('prelogin/HrfilterNewsBlock.vue'))
})
